import * as React from "react"
import { seasons } from "../data/seasons.data"

const Menu = ({ seasonId }) => {
  const [currentPath, setCurrentPath] = React.useState('');

  React.useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  return (
    <ul className="nav">
      <li key="menuItemHome">
        <a
          href="/"
          title="Inicio"
          className={currentPath === '/' ? 'active' : ''}
        >
          Inicio
        </a>
      </li>
      {seasons.map((seasonItem) => {
        const seasonPath = `/tempada-${seasonItem.id}`;
        return (
          <li key={"menuItemSeason" + seasonItem.id}>
            <a
              href={seasonPath}
              title={seasonItem.title}
              className={currentPath.startsWith(seasonPath) ? 'active' : ''}
            >
              {seasonItem.title}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

export default Menu
