import "../styles/styles.css";

import * as React from "react";
import SeasonPage from "../components/seasonPage.component";

// markup
const SeasonTwoPage = () => {
  return <SeasonPage seasonId={3}></SeasonPage>;
};

export default SeasonTwoPage;
