import cartazS1 from "../images/cartaz_s1.jpg"
import cartazS2 from "../images/cartaz_s2.jpg"

export const seasons = [
  {
    id: 1,
    title: "Tempada I",
    subTitle: "UNHA VIAXE POLA HISTORIA DA MÚSICA GALEGA",
    description:
      "Primeira tempada de 6 programas na que facemos unha viaxe pola historia da música galega. As orixes, os imprescindibles do folk, rock, pop ata chegar ao máis mainstream. A plataforma pretende ser un instrumento para promover a riqueza da musica e aproveitar o bo momento actual proporcionado polo actual éxito da musica galega para xerar novos públicos e novos territorios para a musica producida na Galicia.",
    romanNumber: "I",
    logo: cartazS1,
  },
  {
    id: 2,
    title: "Tempada II",
    subTitle: "UNHA VIAXE POLA HISTORIA DA MÚSICA BRASILEIRA",
    description:
      "Esta segunda tempada do podcast Nas Ondas Galegas - Atravessando o Charco pretende viaxar cara o Brasil e a súa rica música. Queremos amosar as conexións entre o xigante sur americano e a Galicia.",
    romanNumber: "II",
    logo: cartazS2,
  },
  {
    id: 3,
    title: "Tempada III",
    subTitle: "PORTUGAL - CRUZANDO A RAIA",
    description:
      "Nesta terceira tempada do noso podcast, emprendemos unha viaxe pola música onde as melodías se entrelazan para tecer unha historia de profundas conexións entre dúas terras irmás: Galicia e Portugal. Desde tempos inmemoriais, estas rexións compartiron máis que fronteiras xeográfica, comparten unha historia entrelazada pola música, a cultura e a identidade. Exploraremos os ritmos e melodías que resoaron ao longo dos séculos, unindo estas dúas terras nun vínculo eterno de harmonía e tradición.",
    romanNumber: "III",
  }
]
