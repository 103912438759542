import React from "react"
import { Helmet } from "react-helmet"
import {
  mainDescription,
  mainSubtitle,
  mainTitle,
  mainUrl,
} from "../data/main.data"
import { seasons } from "../data/seasons.data"
import cartaz from "../images/cartaz.jpg"
import favicon from "../images/favicon.jpg"
import Menu from "./menu.component"

const Authors = () => {
  return (
    <>
      <div style={{ clear: "both" }}>
        <h2>REALIZACIÓN</h2>
        <p>
          Os promotores deste proxecto xa viñan traballando nesta idea cando
          realizaban o Festival Primavera do Cine, o festival de cinema lusófono
          máis importante do noso territorio. O intercambio cultural e
          etnográfico de ambas as comunidades unidas para sempre pola lingua é unha
          das grandes motivacións para desenvolver proxectos coma o que
          presentamos, para difundir a música galega a través do uso da
          Internet, con foco na lusofonía.
        </p>
        <p>
          Son seis episodios por tempada nos que os condutores do programa
          viaxan por diferentes estilos e xéneros da música de autoría ou na
          lingua galega, transmitindo unha sorte de antoloxía musical
          contemporánea na nosa lingua. A Internet rompe barreiras e achega
          persoas e culturas, aproveitar o potencial desta ferramenta
          posibilita a difusión dunha cousa fundamental para nós: a nosa
          lingua.
        </p>
        <p>
          Nada mellor ca a música para achegar as persoas e espertar o
          sentimento da curiosidade, para buscar outras culturas e outros
          lugares.
        </p>
        <p>
          As conversas e a análise do contexto das pezas musicais faranse
          tanto en galego coma en portugués.
        </p>
      </div>
      <div style={{ clear: "both" }}>
        <h2>CONDUTORES</h2>
        <p>O podcast está realizado e conducido por:</p>

        <h3>André de Oliveira</h3>
        <p>
          Poeta, artista visual e xestor
          cultural, é licenciado en Artes Visuais por Unigranrio e realizou un
          curso de formación para xestores públicos e axentes culturais na UERJ
          (Brasil). Foi concelleiro de cultura e turismo na cidade de Duque de
          Caxias, Brasil. Participou de diversos movementos culturais no Brasil,
          entre eles: o grupo cultural Utópicus, o Cineclube Mate Con Angu e a
          Lira de Ouro. Fundou e presidiu a Radio Quarup FM, na cidade de Duque
          de Caxias. Autor do libro de poesías Manual práctico para uso de
          obxectos sensíbeis. Actualmente vive na cidade de Vigo na España, onde
          actúa na produtora Abella Producións, onde xa participou das
          producións do Festival Internacional de Cinema Primavera do Cine de
          Vigo e do Galician Freak Film Festival, alén de actuar no Festival
          Internacional de Poesía Kerouac.
        </p>

        <h3>Juan de Castro</h3>
        <p>
          Vén do eido da enxeñería e a
          arquitectura, mais na última década concentra todas as súas enerxías
          na xestión cultural, como Experto Universitario en Xestión Cultural
          pola USC, e na produción de proxectos cinematográficos e eventos
          culturais. Presidente e capitán de Abella Producións e director do
          GFFF e de Cinema Miúdo de Ames. Foi convidado como xurado e
          programador en festivais e mostras de cinema en España e Portugal como
          Cinanima, FIC Verín, ou Motel X. É xestor do The Wild Fest e foi
          director do Festival Primavera do Cine.
        </p>

        <h3>Míriam P. Álvarez</h3>
        <p>
          Licenciada en Comunicación
          Audiovisual pola Facultade de Ciencias da Información da UCM. Comeza a
          súa andaina colaborando como voluntaria en eventos culturais na cidade
          de Vigo. Desempeñou a labor de Axudante de Dirección nos Festivais
          Primavera do Cine (2018-2019) e GFFF - Galician Freaky Film Festival
          (2017-2021), asumindo a Subdirección deste último desde 2022. Membro
          de Abella Producións desde o seu nacemento desempeña tarefas de
          produción, financiación, comunity manager e/ou edición de vídeo nos
          distintos proxectos da productora. No eido do audiovisual desenvolveu
          tarefas de axudante de produción e dirección en varios videoclips do
          grupo Broken Peach (I miss you, Late night sessions, Like a Gale…).
          Presenta e produce desde 2021 o programa youtubeiro Ministerio do
          Cinema Freak e Cinema Caníbal centrados na divulgación de cinema.
        </p>
      </div>
    </>
  )
}

const Header = ({ seasonId }) => {
  const season = seasons.find((season) => season.id === seasonId)
  const subtitle = season ? `${season.title}: ${season.subTitle}` : mainSubtitle
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" lang="gl-ES" />
        {season ? (
          <title>
            {season.title} | {mainTitle} - {mainDescription}
          </title>
        ) : (
          <title>
            {mainTitle} | {mainSubtitle}
          </title>
        )}
        <link rel="canonical" href={mainUrl} />
        <link rel="shortcut icon" type="image/jpg" href={favicon} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=DM+Serif+Display&display=swap" rel="stylesheet" />
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/brands.js"
        ></script>
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/solid.js"
        ></script>
        <script
          defer
          src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/js/fontawesome.js"
        ></script>
      </Helmet>
      <header>
        <h1>
          {season ? (
            <a href={"/tempada-" + season.id}>
              {mainTitle} - {season.romanNumber}
            </a>
          ) : (
            <a href={mainUrl}>{mainTitle}</a>
          )}
        </h1>
        <h2>{subtitle}</h2>
        <Menu seasonId={seasonId} />
      </header>
      <div className="presentacion">
        <div className="left">
          <h4>O PODCAST</h4>
          <p>
            Abella Producións presenta o seu proxecto: NAS ONDAS
            GALEGAS. Un podcast no que facemos un percorrido pola historia da música galega e
            brasileira. O proxecto vai dirixido aos melómanos da nosa influencia
            lusófona, por iso é presentado en galego e portugués polo noso
            equipo.
          </p>
          {!seasonId ? (
            <>
              <p>
                O proxecto que presentamos pretende crear unha plataforma web
                onde o idioma e a cultura de Galicia sexan o eixe para a
                presentación da música galega e brasileira nas súas variadas
                vertentes. A plataforma pretende ser un instrumento para
                promover a riqueza da música galega e brasileira e aproveitar o
                bo momento actual para xerar novos públicos e novos territorios
                para a música producida en Galicia e no Brasil.
              </p>
              <p>
                Os programas están presentados en galego e portugués, coa
                finalidade de abrir xunto á comunidade lusófona unha
                oportunidade de crear espazos e oportunidades para a música e o
                idioma galego.
              </p>
            </>
          ) : null}
        </div>
        <div className="right">
          {season?.logo ? (
            <img src={season.logo} alt={season.title} />
          ) : (
            <img src={cartaz} alt={mainDescription} />
          )}
        </div>
      </div>
      {!seasonId ? <Authors /> : null}
    </div>
  )
}

export default Header
