export const episodios = [
  {
    id: 1,
    title: "O folclore galego",
    description:
      "Revisión do conxunto de artes trasladadas á música e costumes no noso idioma. O reino de Galicia, unha soa lingua.",
    uri: "https://drive.google.com/file/d/1FMIH9_hwmpF3JNwydBNSNdC38CVtt-An/preview",
    season: 1,
    episode: 1,
  },
  {
    id: 2,
    title: "O son do Camiño",
    description:
      "Neste programa imos abordar as achegas musicais no Camiño de Santiago, as músicas e artistas que tiveran ou foran inspirados polo seu percorrido.",
    uri: "https://drive.google.com/file/d/1-ek6ISmDd3R4YTtQ9QGGnJcWbMttTiui/preview",
    season: 1,
    episode: 2,
  },
  {
    id: 3,
    title: "O rock galego",
    description:
      "Quen di que no se pode bailar rock galego... Neste programa imos presentar os referentes do rock galego e amosar que Galicia tamén é rock ́n roll",
    uri: "https://drive.google.com/file/d/1tfunRizuXI9ebUXHslSV_OWlu8oaj5us/preview",
    season: 1,
    episode: 3,
  },
  {
    id: 4,
    title: "Música popular",
    description:
      "O galego tamén é pop, neste programa imos presentar aos artistas e músicas máis famosas de Galicia, desde a Rianxeira, pasando por Carlos Núñez, sen deixar fóra as famosas orquestras, porque hoxe é día de festa no noso podcast.",
    uri: "https://drive.google.com/file/d/1O5GPfNbSqnnV6q-ofA5U6iRgUmECtmHc/preview",
    season: 1,
    episode: 4,
  },
  {
    id: 5,
    title: "Os imprescindibles",
    description:
      "Ese programa contará cunha coidadosa selección, de perlas galegas, algunhas non foron moi difundidas, mais merecen ser coñecidas polo mundo.... comezando polos nosos primos de lingua lusófonos.",
    uri: "https://drive.google.com/file/d/113B-fHQvcSkkoqofTnxsKpPy8xnqNRf8/preview",
    season: 1,
    episode: 5,
  },
  {
    id: 6,
    title: "Ollando para o futuro",
    description:
      "Galicia mira para o futuro, sexa coa súa industria, sexa co turismo, nós queremos presentar ese futuro de Galicia e do galego a través de súa cultura e de súa música, neste programa presentaremos a nova xeración da musica galega e con ela imos romper as barreiras... as fronteiras e presentar ao mundo... o que a nosa Galicia ten.",
    uri: "https://drive.google.com/file/d/1fpNDvSaCt9O_BkZUU23sEZqVKvW6eovE/preview",
    season: 1,
    episode: 6,
  },
  {
    id: 7,
    title: "Os primeiros sons brasileiros",
    description:
      "Neste episodio imos amosar un chisco das raíces da música brasileira, imos presentar un pouco da música indíxena que era a música do territorio antes da colonización portuguesa e da chegada dos escravos vidos de Africa. Tamén presentaremos os primeiros artistas brasileiros e algunha música de distintas rexións. Falaremos tamén destas primeiras conexións coa música de Galicia.",
    uri: "https://drive.google.com/file/d/1ZYTFfvDizQ-Z98rGSTbv9O-BESGxqdFn/preview",
    season: 2,
    episode: 1,
  },
  {
    id: 8,
    title: "Samba, bossa nova e outras cousiñas",
    description:
      "Neste episodio imos presentar un pouco da historia dos ritmos brasileiros. Comezaremos pola samba, un xénero musical creado en Brasil a partir da influencia da música dous escravos que chegaron ao país. Seguiremos coa bossa nova, outro xénero musical brasileiro que está influenciado pola samba e o jazz, o xénero foi creado na zona de elite da cidade de Río de Janeiro, mentres que a samba era orixinaria das favelas. Sen dúbida, a bossa nova foi un dos grandes responsables de inserir a música e a cultura brasileiras no mundo. Tamén abordaremos outras variantes musicais como o chorinho, un prezado xénero musical pouco coñecido no mundo. Un dos dous principais instrumentos musicais da samba é o pandeiro, coñecido en Galicia como pandeireta, hai algunha conexión?",
    uri: "https://drive.google.com/file/d/1SuDoG8dl41jFDAaRaVlkmo9PFgBGAqpJ/preview",
    season: 2,
    episode: 2,
  },
  {
    id: 9,
    title: "Rock Brasil. Um lugar do caralho",
    description:
      "Neste episodio imos facer una viaxe polo potente rock brasileiro. Neste xenero musical non hai tantos nomes coñecidos no escenario internacional, mais Brasil ten muito artistas e grupos de rock de calidade facendo o bo e vello rock`n roll. A canción “Um lugar do caralho” de Raúl Seixas, aseméllase a unha outra galega, será que tivo algunha influencia?",
    uri: "https://drive.google.com/file/d/13U0uUsFyl7nbW0GeXEy2n1JlOqXgXokC/preview",
    season: 2,
    episode: 3,
  },
  {
    id: 10,
    title: "Mistura Brasil",
    description:
      "Forró, funk, cocô, embolada, Brasil é un auténtico caldeiro de ritmos musicais. Neste episodio imos presentar un pouco desta rica diversidade. Tamén imos abordar as posibles influencias da música galega na música brasileira.",
    uri: "https://drive.google.com/file/d/1D3elTgGonpXW92KC3p-ail-8lPVRxH3K/preview",
    season: 2,
    episode: 4,
  },
  {
    id: 11,
    title: "Os imprescindibles",
    description:
      "Este programa é para gozar do mellor da Música Popular Brasileira, neste episodio escoitarás unha constelación de artistas e cancións como: Tom Jobim, Caetano Veloso, Chico Buarque de Holanda, Maria Bethania, Elis Regina, Gilberto Gil, Vinicius de Moraes ... uf .... hai tantos que non creo que entren nun só programa. Buscaremos se hai algunha inspiración, inflúe o alento divino dalgunha superestrela brasileira coa música galega. Tamén falamos do paso destes artistas polo noso país.",
    uri: "https://drive.google.com/file/d/1DgOhfaTTRmmomEMwQ7-2hGUBqOD1_ZB7/preview",
    season: 2,
    episode: 5,
  },
  {
    id: 12,
    title: "Para onde vai o Brasil?",
    description:
      "Neste episodio queremos abordar un Brasil musical que vai máis alá dos grandes nomes, un Brasil que se mestura con outros países e culturas para xerar novos produtos e perspectivas culturais. Un xigante que quere seguir sendo unha potencia cultural. Neste episodio mostraremos posibilidades e algunhas colaboracións entre artistas actuais brasileiros e galegos.",
    uri: "https://drive.google.com/file/d/1HIrwcRqAnr1KGaYdR7-23EV-7C46s4iy/preview",
    season: 2,
    episode: 6,
  },
  {
    id: 13,
    title: "A história da música Galaico-Portuguesa",
    description:
      "Este episodio mergúllase nas profundidades da historia compartida entre Galicia e Portugal a través da súa música. Desde os cantares de xesta medievais ata as influencias árabes e celtas, exploraremos como a música foi un reflexo da identidade e as experiencias compartidas destes dous pobos ao longo dos séculos. A través de exemplos musicais e análises históricas, descubriremos como a música galaico-portuguesa evolucionou e enriqueceuse co paso do tempo, servindo como un testemuño vivo da conexión entre estas dúas culturas.",
    uri: "https://drive.google.com/file/d/1DKPX1IHe-btVLc45mlUNq39qFsxnIsdn/preview",
    season: 3,
    episode: 1,
  },
  {
    id: 14,
    title: "O fado",
    description:
      "O fado, xénero musical emblemático de Portugal, é o centro de atención deste episodio. Exploraremos a melancolía e a paixón que caracterizan a este xénero único, así como a súa profunda conexión coa cultura e a identidade portuguesa. Desde os seus humildes comezos nas rúas de Lisboa ata o seu recoñecemento como Patrimonio Cultural Inmaterial da Humanidade pola UNESCO, desentrañaremos os misterios e as emocións que envolven ao fado e o seu impacto na música portuguesa.",
    uri: "https://drive.google.com/file/d/12asvEWcxS9ZJlqUgCWzH_ftIeLDxhPCZ/preview",
    season: 3,
    episode: 2,
  },
  {
    id: 15,
    title: "Música de protesto",
    description:
      "Neste episodio, penetrámonos no poderoso mundo da música de protesta en Portugal. Desde as cancións revolucionarias que acompañaron as loitas pola liberdade e a xustiza social ata as voces dos artistas que desafían o status quo a través das súas letras e melodías, exploraremos como a música foi unha ferramenta vital para expresar as inxustizas e as aspiracións do pobo portugues principalmente as musicas que foron referencias no 25 de abril que este ano conmemora seu 50 aniversario",
    uri: "https://drive.google.com/file/d/1E93EB_cd0gnN0vsBp2t0dNmMbhrk3G74/preview",
    season: 3,
    episode: 3,
  },
  {
    id: 16,
    title: "Descolonizando a musica portuguesa",
    description:
      "Este episodio céntrase nas influencias dás antigas colonias  e outras influencias globais na música de Portugal.  Exploraremos como os ritmos e os sons de África e Brasil deixaron unha marca indeleble na música portuguesa, así como a influencia doutras culturas e tradicións musicais na creación dun mosaico sonoro único e diverso nesta rexión. Desde a música tradicional ata a fusión contemporánea, descubriremos como estas influencias enriqueceron e diversificaron o panorama musical portugués.",
    uri: "https://drive.google.com/file/d/1LTe1sz1ZnWJ8Eo-YgjwCnppjMVhs2a_O/preview",
    season: 3,
    episode: 4,
  },
  {
    id: 17,
    title: "O pop português",
    description:
      "Neste episodio, mergullámonos no vibrante mundo do pop portugués. Desde os íconos do pop portugués ata os artistas emerxentes que están a dar forma ao panorama musical contemporáneo, exploraremos a evolución e a diversidade do pop en Portugal e a súa relación coa música portuguesa. A través de exemplos musicais e entrevistas con artistas, descubriremos como o pop portugués reflicte a identidade e as experiencias compartidas da sociedade portuguesa, así como a súa conexión coa música de Galicia.",
    uri: "https://drive.google.com/file/d/1Y5YDHmDTQHg-Jl_5o3Ixn0P6jL-SrMqA/preview",
    season: 3,
    episode: 5,
  },
  {
    id: 18,
    title: "As novidades",
    description:
      "Neste episodio final, exploramos as últimas novidades na música portuguesa. Desde novos talentos emerxentes ata as últimas tendencias e fusións musicais, descubriremos como a música de Portugal segue evolucionando e reinventándose no século XXI. Exploraremos as novas fronteiras da música portuguesa e como continúa inspirando e emocionando ás audiencias en todo o mundo.",
    uri: "https://drive.google.com/file/d/1gGcOMRhWw0GL8S5LY9j594rqZI8aGW_G/preview",
    season: 3,
    episode: 6,
  },
]
