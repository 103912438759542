import "../styles/styles.css"

import * as React from "react"

import { Episodio } from "../components/episodio.component"
import Footer from "../components/footer.component"
import Header from "../components/header.component"
import { episodios } from "../data/episodios.data"
import { seasons } from "../data/seasons.data"

// markup
const SeasonPage = ({seasonId}) => {
  const season = seasons.find(season => season.id === seasonId);
  if (!season) {
    // redirect to 404
    return null;
  }
  return (
    <main id="nasondasgalegas" lang="gl-ES">
      <Header seasonId={seasonId}></Header>
      <div style={{ clear: "both" }}>
        <h2 className="season-title">{season.title}</h2>
        <h3 className="season-subtitle">{season.subTitle}</h3>
        {season.image && <img src={season.image} alt={season.title} className="season-image" />}
        {season.description && <p className="season-description">{season.description}</p>}
        {episodios
          .filter((episodio) => episodio.season === season.id)
          .map((episodio) => {
            return (
              <Episodio
                key={"s" + episodio.season + "e" + episodio.episode}
                episodio={episodio}
              ></Episodio>
            )}
        )}
      </div>
      <Footer></Footer>
      <div className="nav-to-top">
        <a href="#nasondasgalegas">⇧ Volver</a>
      </div>
    </main>
  )
}

export default SeasonPage;
